import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Svg from "../Svg";

import { ReactComponent as Binoculars } from "../../assets/binoculars.svg";
import { ReactComponent as Forecast } from "../../assets/forecast.svg";
import { ReactComponent as Snowflake } from "../../assets/snowflake.svg";
import { ReactComponent as Weather } from "../../assets/weather.svg";

import PersonContext from "../../util/PersonContext";
import { turquoise } from "../../sass/colors";

function ObservationsNav() {
  const { person } = useContext(PersonContext);
  return (
    <div className={"has-dropright"}>
      <div className={"dropright-header"}>
        <Svg color={"white"} size={"1.5rem"} Component={Binoculars} />
        <span>Observations</span>
      </div>
      <div className="dropright">
        <div className={"nav-section"}>
          <div className={"nav-section-header"}>
            <Svg size={"1.5rem"} color={turquoise} Component={Weather} />
            <h1>Météorologie</h1>
          </div>
          <Link
            className="dropright-item"
            to={`/stations/weather`}
            onClick={(e) => e.target.blur()}
          >
            Stations
          </Link>
          {person?.isHydroMeteo() && (
            <>
              <Link
                className="dropright-item"
                to={`/states/rain`}
                onClick={(e) => e.target.blur()}
              >
                Scan
              </Link>
              <Link
                className="dropright-item"
                to={`/lanaudiere/table`}
                onClick={(e) => e.target.blur()}
              >
                Tableau récapitulatif de Lanaudière
              </Link>
              {/*<Link*/}
              {/*  className="dropright-item"*/}
              {/*  to={`/quebec-vortex/mrms`}*/}
              {/*  onClick={(e) => e.target.blur()}*/}
              {/*>*/}
              {/*  MRMS*/}
              {/*</Link>*/}
            </>
          )}
        </div>
        <div className={"nav-section"}>
          <div className={"nav-section-header"}>
            <Svg size={"1.5rem"} color={turquoise} Component={Forecast} />
            <h1>Hydrologie</h1>
          </div>
          <Link
            className={"dropright-item"}
            to={"/stations/hydro"}
            onClick={(e) => e.target.blur()}
          >
            Stations
          </Link>
          <Link
            className="dropright-item"
            to={`/stations/cameras`}
            onClick={(e) => e.target.blur()}
          >
            Caméras
          </Link>
          {person?.isHydroMeteo() && (
            <>
              <Link
                className="dropright-item"
                to={`/states/flow`}
                onClick={(e) => e.target.blur()}
              >
                Scan
              </Link>
              <Link
                className={"dropright-item"}
                to={`/states/level`}
                onClick={(e) => e.target.blur()}
              >
                Scan régional des niveaux
              </Link>
            </>
          )}
        </div>
        {(person?.clientHasIceSub() && (
          <div className={"nav-section"}>
            <div className={"nav-section-header"}>
              <Svg size={"1.5rem"} color={turquoise} Component={Snowflake} />
              <h1>Glaciologie</h1>
            </div>
            <Link
              className={"dropright-item"}
              to={"/stations/ice"}
              onClick={(e) => e.target.blur()}
            >
              Graphiques de glace
            </Link>
          </div>
        )) ||
          null}
        {(person?.clientHasIceSub() && (
          <div className={"nav-section"}>
            <div className={"nav-section-header"}>
              <Svg size={"1.5rem"} color={turquoise} Component={Binoculars} />
              <h1>Hydrométéorologie</h1>
            </div>
            <Link
              className={"dropright-item"}
              to={"/quebec-vortex/snow-observations"}
              onClick={(e) => e.target.blur()}
            >
              Neige au sol
            </Link>
          </div>
        )) ||
          null}
        {person?.clientHasSub() && (
          <div className={"nav-section"}>
            <div className={"nav-section-header"}>
              <Svg size={"1.5rem"} color={turquoise} Component={Binoculars} />
              <h1>Lanaudière</h1>
            </div>
            <Link
              className={"dropright-item"}
              to={"/lanaudiere/map"}
              onClick={(e) => e.target.blur()}
            >
              Carte météo de Lanaudière
            </Link>
            <Link
              className={"dropright-item"}
              to={"/lanaudiere/hydro"}
              onClick={(e) => e.target.blur()}
            >
              Hydrologie
            </Link>
            <Link
              className={"dropright-item"}
              to={"/lanaudiere/temperature"}
              onClick={(e) => e.target.blur()}
            >
              Température
            </Link>
            <Link
              className={"dropright-item"}
              to={"/lanaudiere/precipitation-24"}
              onClick={(e) => e.target.blur()}
            >
              Précipitations (24h)
            </Link>
            <Link
              className={"dropright-item"}
              to={"/lanaudiere/precipitation-48"}
              onClick={(e) => e.target.blur()}
            >
              Précipitations (48h)
            </Link>
            <Link
              className={"dropright-item"}
              to={"/lanaudiere/humidex"}
              onClick={(e) => e.target.blur()}
            >
              Humidex
            </Link>
            <Link
              className={"dropright-item"}
              to={"/lanaudiere/nbm-3-days"}
              onClick={(e) => e.target.blur()}
            >
              NBM: prévision 3 jours
            </Link>
          </div>
        )}
        {person?.isCoaticook() && (
          <div className={"nav-section"}>
            <div className={"nav-section-header"}>
              <Svg size={"1.5rem"} color={turquoise} Component={Binoculars} />
              <h1>Coaticook</h1>
            </div>
            <Link
              className={"dropright-item"}
              to={"/coaticook"}
              onClick={(e) => e.target.blur()}
            >
              Observations hydrologiques
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default ObservationsNav;
