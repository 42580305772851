import React, { useContext, useState } from "react";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faCaretDown,
  faCircleUser,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";

import auth from "../../services/auth";
import PersonContext from "../../util/PersonContext";

import pegaseLogo from "../../assets/pegase_horizontal.png";
import "./Social.css";

function Social() {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const { person, setPerson } = useContext(PersonContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isPegase = !!pathname?.includes("pegase");
  const handleLogout = () => {
    setDropdownIsOpen(false);
    auth.logout();
    setPerson(null);
    navigate("/");
  };
  return (
    <nav id={"page-top"} className={"social"}>
      <div>
        {isPegase && (
          <img
            src={pegaseLogo}
            width={550}
            height={61}
            alt={
              "Logo de PEGASE, Prévisions ensemblistes, gestion avancée et sécuritaire de l'eau, un produit d'Hydro Météo"
            }
            style={{ marginLeft: "2rem" }}
          />
        )}
      </div>
      <div>
        <a
          href={"https://www.facebook.com/hydrometeo"}
          target={"_blank"}
          rel={"noreferrer"}
        >
          <FontAwesomeIcon
            icon={faFacebook}
            color={"#003F61"}
            style={{ fontSize: "2rem" }}
          />
        </a>
        {/*<a href={""} target={"_blank"} rel={"noreferrer"}>*/}
        {/*  <FontAwesomeIcon*/}
        {/*    icon={faLinkedin}*/}
        {/*    color={"#003F61"}*/}
        {/*    style={{ fontSize: "2rem" }}*/}
        {/*  />*/}
        {/*</a>*/}
        <a
          href={"https://www.instagram.com/hydrometeo_"}
          target={"_blank"}
          rel={"noreferrer"}
        >
          <FontAwesomeIcon
            icon={faInstagram}
            color={"#003F61"}
            style={{ fontSize: "2rem" }}
          />
        </a>

        {(person && (
          <div className={`logged${dropdownIsOpen ? " active" : ""}`}>
            <div onClick={() => setDropdownIsOpen(!dropdownIsOpen)}>
              {`${person.firstName} ${person.lastName}`}
              <FontAwesomeIcon
                icon={faCaretDown}
                color={"#003F61"}
                style={{ fontSize: "1.5rem" }}
              />
              <FontAwesomeIcon
                icon={faCircleUser}
                color={"#003F61"}
                style={{ fontSize: "2rem" }}
              />
            </div>
            <div className={"dropdown"}>
              <Link
                className={"dropdown-item"}
                to={"/persons/me"}
                onClick={(e) => setDropdownIsOpen(false)}
              >
                <FontAwesomeIcon icon={faCircleUser} color={"#003F61"} />
                <span>Profil</span>
              </Link>
              <button className={"dropdown-item"} onClick={handleLogout}>
                <FontAwesomeIcon icon={faPowerOff} color={"#003F61"} />
                <span>Déconnexion</span>
              </button>
            </div>
          </div>
        )) || (
          <Link
            className={"button is-primary"}
            to={"/persons/connection/login"}
            onClick={(e) => e.target.blur()}
          >
            Se connecter
          </Link>
        )}
      </div>
    </nav>
  );
}

export default Social;
